<template>
  <div title="List Affiliate Sharing">
    <!-- <b-container fluid="sm" class="mb-5">
      <b-row class="text-right">
        <b-col>
          <b-button @click="createAffiliateShare" variant="success">
            <plus-icon size="1.2x" class="custom-class"></plus-icon>ADD
          </b-button>
        </b-col>
      </b-row>
    </b-container> -->

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }" :line-numbers="true" :pagination-options="{
  enabled: true,
  perPage: pageLength
}">
      <template slot="table-row" slot-scope="props">

        <!-- Column: Name -->
        <span v-if="props.column.field === 'image'" class="text-nowrap">
          <b-img :src="imgUrl(props.row.image)" class="mx-1" v-bind="mainProps"></b-img>
        </span>

        <!-- Column: Status -->
        <!-- <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span> -->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'status'" style="white-space: nowrap;">
          <span v-if="props.row.status == 1">
            <label class="approved_label" variant="success">Transferred</label>
          </span>
          <span v-else-if="props.row.status == 2">
            <label class="rejected_label" variant="danger">Rejected</label>
          </span>
          <span v-else>
            <b-button class="requested_btn"
              @click="transferRequest(props.row.user_id, props.row.connected_affiliate_id, props.row.amount, props.row.id)"
              variant="success">
              Transfer
            </b-button>
            <b-button class="requested_btn" @click="rejectedRequest(props.row.user_id, props.row.id)" variant="danger">
              Reject
            </b-button>
          </span>
        </span>

        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <b-dropdown-item @click="deleteEmployee(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select v-model="pageLength" :options="['5', '10', '25']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BContainer, BRow, BCol, BImg
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import { PlusIcon } from 'vue-feather-icons'
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Database
import { db } from "@/main";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BContainer,
    BRow,
    BCol,
    BImg,
    PlusIcon,
  },
  data() {
    return {
      mainProps: { blank: false, width: 75, height: 75, class: 'm1' },
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'User Id',
          field: 'user_id',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        // {
        //   label: 'Product Name',
        //   field: 'product_name',
        // },
        {
          label: 'Descrition',
          field: 'reason',
          sortable: false,
        },
        {
          label: 'Dated',
          field: 'created',
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  methods: {
    transferRequest(user_id, connected_affiliate_id, amount, id) {
      // alert(connected_affiliate_id)
      store.dispatch('app/commitActivateLoader');
      var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
      // var base_url = window.location.host;
      const url = this.$VUE_APP_API_ENDPOINT + "affiliate-charge";
      const transferData = {
        // Id: doc.id,
        connected_affiliate_id: connected_affiliate_id,
        amount: Math.round(amount * 100),
        // userEmail: userData.email,
        // userName: userData.username,
      }
      axios.post(url, transferData)
        .then(response => {
          console.log(response);
          // console.log(response.data.status);
          // if (response.status == 200) {
          //   this.showNotification('Success, Added Successfully!', 'AlignJustifyIcon', 'Success');
          //   window.location.href = response.data;
          //   store.dispatch('app/commitDeactivateLoader');
          // } else {
            alert();
            store.dispatch('app/commitDeactivateLoader');
          //   this.showNotification('Error! Email Failed', 'AlertTriangleIcon', 'Danger');
          //   // this.$router.push('/companies/index');
          // }

        })
      // db.collection('affiliate_stripe_users').doc(connected_affiliate_id)
      //               .get().then(snapshot => {
      //                   this.stripe_connect_id = snapshot.data().stripe_connect_id;
      //                   // this.country_code = snapshot.data().code;
      //                   // console.log(this.country)
      //                   console.log(snapshot.data());
      //               })
      // db.collection("users")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       if (doc.id == user_id) {
      //         console.log(doc.id, " => ", doc.data());
      //         // let user_email = doc.data().email

      //         console.log(doc.data().firstname + ' ' + doc.data().lastname);
      //         const auth = {
      //           firstname: doc.data().firstname,
      //           fullname: doc.data().firstname + ' ' + doc.data().lastname,
      //           email: doc.data().email,
      //           created: date,
      //           modified: date
      //         };
      //         // Correct username is 'foo' and password is 'bar'
      //         const url = base_url + '/api/cancel-request';
      //         this.success = false;
      //         this.error = null;

      //         try {
      //           const res = axios.post(url, { auth }).then(
      //             (res) => {
      //               console.log(res.data)
      //               store.dispatch('app/commitDeactivateLoader')
      //               this.showNotification('Success, Request Approved Send Mail SuccessFully!', 'AlertTriangleIcon', 'success');
      //               // this.$router.push(res.data.url)
      //               // window.location.href = res.data.url;
      //             });
      //           console.log(res);
      //           this.success = true;
      //         } catch (err) {
      //           this.error = err.message;
      //         }
      //         store.dispatch('app/commitDeactivateLoader');
      //       }
      //     })
      //   })

      // db.collection("affiliates")
      //   .where("user_id", "==", user_id)
      //   .where("product_id", "==", product_id)
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       console.log(doc.id, " => ", doc.data());
      //       db.collection("affiliates")
      //         .doc(doc.id)
      //         .update({
      //           delete_id: 1,
      //           created: date,
      //           modified: date,
      //         })
      //         .then((doc) => {
      //           console.log("Updated Successfully!");
      //           store.dispatch('app/commitDeactivateLoader');
      //           this.$router.push({ name: 'cancel-orders' });
      //         })
      //     })
      //   })

      // db.collection("order_details")
      //   .where("uid", "==", user_id)
      //   .where("subscription", "==", product_id)
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       console.log(doc.id, " => ", doc.data());
      //       db.collection("order_details")
      //         .doc(doc.id)
      //         .update({
      //           delete_id: 1,
      //           created: date,
      //           modified: date,
      //         })
      //         .then((doc) => {
      //           console.log("Updated Successfully!");
      //           store.dispatch('app/commitDeactivateLoader');
      //         })
      //     })
      //   })

      // db.collection("cancle_subscription")
      //   .doc(id)
      //   .update({
      //     status: 1,
      //     created: date,
      //     modified: date,
      //   })
      //   .then((doc) => {
      //     console.log("Updated Successfully!");
      //     store.dispatch('app/commitDeactivateLoader');
      //     // this.$router.push({ name: 'cancel-orders' });
      //   })
    },

    rejectedRequest(user_id, id) {
      // alert(id)
      store.dispatch('app/commitActivateLoader');
      var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
      db.collection("cancle_subscription")
        .doc(id)
        .update({
          status: 2,
          created: date,
          modified: date,
        })
        .then((doc) => {
          console.log("Updated Successfully!");
          store.dispatch('app/commitDeactivateLoader');
          // this.$router.push({ name: 'cancel-orders' });
        })
    },

    readCategories() {
      store.dispatch('app/commitActivateLoader');

      this.rows = [];
      var i = 1;

      db.collection("affiliate_transfer_request")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.rows.push({
              index: i,
              id: doc.id,
              amount: doc.data().amount,
              connected_affiliate_id: doc.data().connected_affiliate_id,
              reason: "This user is comleted their affiliate sharing, now you can approved and transfer their commission",
              user_id: doc.data().user_id,
              status: doc.data().status,
              created: doc.data().created,
              modified: doc.data().modified,
            });
            console.log(doc.id, " => ", doc.data());
            i++;
            store.dispatch('app/commitDeactivateLoader');
          });
        }).catch((error) => {
          console.log("Error getting documents: ", error);
          store.dispatch('app/commitDeactivateLoader');
        });
    },
    deleteEmployee(id) {
      if (confirm("Do you really want to delete?")) {
        db.collection("cancle_subscription")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
            this.readCategories();
            this.$toasted.success("Success, Deleted Successfully!", {
              icon: "check",
              //icon : 'error_outline'
            });
            //this.$router.push('/users/index');
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      }
    },
    showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    imgUrl(url) {
      return url.length > 0 ? url : null;
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.readCategories();
    console.log(this.readCategories);
  }
}
</script>
<style>
.requested_btn {
  padding: 6px 6px 6px 6px;
  margin: 5px 5px 5px 5px;
}

.rejected_label {
  background-color: #e63233;
  border-color: #e42728;
  color: white;
  border-radius: 17px;
  padding: 6px 6px 6px 6px;
  margin: 5px 5px 5px 5px;
}

.approved_label {
  background-color: #22a75d;
  ;
  border-color: #1f9d57;
  ;
  color: white;
  border-radius: 17px;
  padding: 6px 6px 6px 6px;
  margin: 5px 5px 5px 5px;
}
</style>